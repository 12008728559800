import qs from 'qs'
import { getCockpitLocale } from './i18n'
import { Locale } from '@/types'

export const urlWithQueryParams = (
  path: string,
  params: Record<string, unknown>
) => {
  if (params.locale) {
    params.locale = getCockpitLocale(params.locale as Locale)
  }

  const query = qs.stringify(params, { addQueryPrefix: true })
  return `${path}${query}`
}
