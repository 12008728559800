import { Locale } from '@/types'

export type CockpitLocale = 'pt_BR' | 'en' | 'es'

export const createLocalizableTag = (tag: string, locale: Locale) => {
  return `${tag}-${locale}`
}

export const getCockpitLocale = (locale: Locale = 'pt'): CockpitLocale => {
  if (locale === 'pt') {
    return 'pt_BR'
  }

  return locale
}
