import { cockpitUrl } from '@/utils/env'
import { CockpitError } from './CockpitError'

interface CockpitFetchProps {
  method?: 'GET' | 'POST'
  resource: string
  cache?: RequestCache
  headers?: HeadersInit
  payload?: BodyInit
  revalidate?: NextFetchRequestConfig['revalidate']
  tags?: string[]
}

const cockpitFetch = async <T>({
  method = 'GET',
  resource,
  headers,
  payload,
  tags,
  revalidate,
  ...rest
}: CockpitFetchProps): Promise<{ status: number; body: T } | never> => {
  const endpoint = `${cockpitUrl}/${resource}`

  try {
    const response = await fetch(endpoint, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      ...rest,
      ...(payload && { body: payload }),
      ...((tags || revalidate) && {
        next: {
          ...(tags && { tags }),
          ...(revalidate && { revalidate })
        }
      })
    })

    const body = await response.json()
    const data = { status: response.status, body }

    if (!response.ok) {
      throw new CockpitError(`Failed [${method}] ${endpoint}`, data)
    }

    return data
  } catch (error) {
    if (error instanceof CockpitError) {
      throw new CockpitError(error.message, error.params)
    }

    throw error
  }
}

export { cockpitFetch }
